import React, { forwardRef } from 'react'
import type { ButtonHTMLAttributes } from 'react'
import { navigate } from 'gatsby'
import { cn } from '../../../../../utils/classnames'

export type PaginationButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
    isActive?: boolean
    href: string
}

// eslint-disable-next-line react/display-name
const PaginationButton = forwardRef<HTMLButtonElement, PaginationButtonProps>(
    ({ children, isActive, href, ...props }, ref) => {
        return (
            <button
                ref={ref}
                onClick={() => navigate(href)}
                role='button'
                className={cn(
                    `min-h-12 min-w-12 inline-flex justify-center items-center !bg-grey-90 hover:!bg-primary text-grey-20 py-2 px-3 text-sm rounded-full focus:outline-none focus:!bg-primary hover:text-white focus:text-white disabled:opacity-50 disabled:pointer-events-none transition-colors duration-300`,
                    {
                        '!bg-primary text-white': isActive,
                    },
                )}
                {...props}>
                {children}
            </button>
        )
    },
)

export type PageInfo = {
    currentPage: number
    hasPreviousPage: boolean
    hasNextPage: boolean
    itemCount: number
    pageCount: number
    perPage?: number
    totalCount: number
}

export const Pagination = ({ pageInfo, path }: { pageInfo: PageInfo; path: string }) => {
    const { currentPage, hasPreviousPage, hasNextPage, pageCount } = pageInfo

    const createPageLink = (page: number) => `${path}/${page}`

    const maxButtons = 9
    const pageButtons = []

    if (pageCount <= maxButtons) {
        for (let page = 1; page <= pageCount; page++) {
            pageButtons.push({
                page,
                isActive: page === currentPage,
                link: createPageLink(page),
            })
        }
    } else {
        const leftPad = 3
        const rightPad = 3
        const middlePad = maxButtons - leftPad - rightPad

        const startPage = Math.max(1, currentPage - Math.floor(middlePad / 2))
        const endPage = Math.min(pageCount, currentPage + Math.floor(middlePad / 2))

        for (let page = 1; page <= leftPad; page++) {
            pageButtons.push({
                page,
                isActive: page === currentPage,
                link: createPageLink(page),
            })
        }

        if (startPage > leftPad + 1) {
            pageButtons.push({
                page: '...',
                isActive: false,
                link: '#',
            })
        }

        for (let page = startPage; page <= endPage; page++) {
            if (page > leftPad && page < pageCount - rightPad + 1) {
                pageButtons.push({
                    page,
                    isActive: page === currentPage,
                    link: createPageLink(page),
                })
            }
        }

        if (endPage < pageCount - rightPad) {
            pageButtons.push({
                page: '...',
                isActive: false,
                link: '#',
            })
        }

        for (let page = pageCount - rightPad + 1; page <= pageCount; page++) {
            pageButtons.push({
                page,
                isActive: page === currentPage,
                link: createPageLink(page),
            })
        }
    }

    return (
        <nav className='flex items-center gap-x-1' aria-label='Pagination'>
            <ul className='flex items-center gap-x-1'>
                <li>
                    <PaginationButton
                        href={path}
                        disabled={!hasPreviousPage}
                        aria-label={`Go to the first page`}
                        aria-disabled={!hasPreviousPage ? 'true' : 'false'}>
                        <svg
                            height={16}
                            width={16}
                            viewBox='0 0 24 24'
                            xmlns='http://www.w3.org/2000/svg'
                            className='shrink-0 size-3.5'>
                            <g
                                fill='none'
                                stroke='currentColor'
                                strokeLinecap='round'
                                strokeLinejoin='round'
                                strokeWidth={2}>
                                <path d='m17 18-6-6 6-6' />
                                <path d='M7 6v12' />
                            </g>
                        </svg>
                    </PaginationButton>
                </li>
                <li>
                    <PaginationButton
                        href={currentPage <= 2 ? path : createPageLink(currentPage - 1)}
                        disabled={!hasPreviousPage}
                        aria-disabled={!hasPreviousPage ? 'true' : 'false'}
                        aria-label={`Go to the previous page`}>
                        <svg
                            height={16}
                            width={16}
                            viewBox='0 0 24 24'
                            className='shrink-0 size-3.5'
                            xmlns='http://www.w3.org/2000/svg'>
                            <g
                                fill='none'
                                stroke='currentColor'
                                strokeLinecap='round'
                                strokeLinejoin='round'
                                strokeWidth={2}>
                                <path d='m15 18-6-6 6-6' />
                            </g>
                        </svg>
                    </PaginationButton>
                </li>

                {pageButtons.map((button) => (
                    <li key={button.page}>
                        <PaginationButton
                            href={button.link}
                            isActive={button.isActive}
                            aria-label={`Go to page ${button.page}`}
                            aria-current={button.isActive ? 'page' : undefined}>
                            {button.page}
                        </PaginationButton>
                    </li>
                ))}

                <li>
                    <PaginationButton
                        href={createPageLink(currentPage + 1)}
                        disabled={!hasNextPage}
                        aria-disabled={!hasNextPage ? 'true' : 'false'}
                        aria-label={`Go to the next page`}>
                        <svg
                            height={16}
                            width={16}
                            viewBox='0 0 24 24'
                            className='shrink-0 size-3.5'
                            xmlns='http://www.w3.org/2000/svg'>
                            <g
                                fill='none'
                                stroke='currentColor'
                                strokeLinecap='round'
                                strokeLinejoin='round'
                                strokeWidth={2}>
                                <path d='m9 18 6-6-6-6' />
                            </g>
                        </svg>
                    </PaginationButton>
                </li>
                <li>
                    <PaginationButton
                        href={createPageLink(pageCount)}
                        disabled={!hasNextPage}
                        aria-disabled={!hasNextPage ? 'true' : 'false'}
                        aria-label={`Go to the last page`}>
                        <svg
                            height={16}
                            width={16}
                            viewBox='0 0 24 24'
                            className='shrink-0 size-3.5'
                            xmlns='http://www.w3.org/2000/svg'>
                            <g
                                fill='none'
                                stroke='currentColor'
                                strokeLinecap='round'
                                strokeLinejoin='round'
                                strokeWidth={2}>
                                <path d='m7 18 6-6-6-6' />
                                <path d='M17 6v12' />
                            </g>
                        </svg>
                    </PaginationButton>
                </li>
            </ul>
        </nav>
    )
}
