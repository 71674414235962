import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import { Helmet } from 'react-helmet'
import { cn } from '../utils'
import { Pagination } from '../hanzo/lib/components/components/pagination'
import type { PageInfo } from '../hanzo/lib/components/components/pagination'
import Layout from '../components/Layout'
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'
import * as myStyles from './styles.module.scss'

// Needs to be class component in order to pass variables to GraphQL query
// Static queries, do not accept variables
// https://www.gatsbyjs.com/docs/how-to/querying-data/use-static-query/#known-limitations
interface StateProps {
    pageContext: PageContext
}

interface PageContext {
    currentPage: number
    numPages: number
}

const dateOptions = {
    year: 'numeric',
    month: 'short',
    day: '2-digit',
} as const

type Author = {
    id: string
    name: string
    display: boolean
    image: {
        id: string
        gatsbyImageData: IGatsbyImageData
    }
}

type BlogPost = {
    title: string
    subtitle: string
    slug: string
    id: string
    date: string
    updatedAt: string
    description: string
    heroimage: {
        id: string
        gatsbyImageData: IGatsbyImageData
    }
    author: Author
    tags: Array<{ tagTitle: string; id: string }>
    categories: Array<{ categoryTitle: string; id: string }>
}

type BlogPostList = Array<{ node: BlogPost }>

type BlogPage = {
    id: string
    title: {
        title: string
    }
    keywords: {
        keywords: string
    }
    description: {
        description: string
    }
    oGimage: {
        title: string
        fluid: {
            srcWebp: string
            srcSetWebp: string
        }
        description: string
        updatedAt: string
    }
}

const styles: any = myStyles

const BlogPostCard = ({ blogpost }: { blogpost: BlogPost }) => {
    const year = new Date(blogpost.date).getFullYear()
    const month = Intl.DateTimeFormat('en-US', { month: '2-digit' }).format(new Date(blogpost.date))

    return (
        <div
            className={cn(
                'flex flex-col rounded-2xl border border-grey-200 overflow-hidden group hover:scale-105 transition-all duration-300 hover:shadow-2xl',
            )}>
            <a
                href={`/blog/${year}/${month}/${blogpost.slug}/`}
                className='overflow-hidden rounded-lg aspect-w-16 mb-4'>
                <GatsbyImage
                    className='object-cover w-full h-full'
                    image={blogpost.heroimage.gatsbyImageData}
                    alt='Blog list'
                />
            </a>

            <a
                href={`/blog/${year}/${month}/${blogpost.slug}/`}
                className={cn('flex flex-col flex-1 px-4 py-2')}>
                <h2 className='text-bd2'>{blogpost.title}</h2>
                <p className='mt-1'>{blogpost.subtitle.substring(0, 70)}&hellip;</p>
            </a>

            <div className='p-4'>
                <span className='inline-flex gap-3 items-center text-bd1'>
                    <span className='rounded-full h-16 w-16 overflow-hidden'>
                        <GatsbyImage
                            className='rounded-full h-16 w-16 object-cover'
                            image={blogpost.author.image.gatsbyImageData}
                            alt={blogpost.author.name}
                        />
                    </span>

                    <span className='inline-flex flex-col gap-0.5 text-grey-50 text-sm'>
                        <span className='!font-bold'>{blogpost.author.name}</span>
                        <span>
                            {new Date(blogpost.date).toLocaleDateString('en-US', dateOptions)}
                        </span>
                    </span>
                </span>
            </div>
        </div>
    )
}

const LatestBlogPost = ({ blogpost }: { blogpost: BlogPost }) => {
    const year = new Date(blogpost.date).getFullYear()
    const month = Intl.DateTimeFormat('en-US', { month: '2-digit' }).format(new Date(blogpost.date))

    return (
        <div className='row'>
            <div className='col-xl-11 col-lg-11 col-md-12 col-11 px-5 mb-5 ml-3 '>
                <div className='row align-items-center'>
                    <div className='col-md-6 col-lg-7 col-xl-6 offset-xl-1 offset-lg-0  col-12 px-0'>
                        <a href={`/blog/${year}/${month}/${blogpost.slug}/`}>
                            <div className='d-none d-sm-block'>
                                <GatsbyImage
                                    className={`${styles.featured_image}`}
                                    image={blogpost.heroimage.gatsbyImageData}
                                    alt={blogpost.description}
                                />
                            </div>

                            <div className='d-sm-none d-md-none d-lg-none d-xl-none'>
                                <GatsbyImage
                                    className={`${styles.featured_image}`}
                                    image={blogpost.heroimage.gatsbyImageData}
                                    alt={blogpost.description}
                                />
                            </div>
                        </a>
                    </div>

                    <div className='col-md-6 col-lg-5  col-xl-4 col-12'>
                        <div className='pl-4 mobile-padding-reset'>
                            <a href={`/blog/${year}/${month}/${blogpost.slug}/`}>
                                <div className='pt-4 lg-reset-margin-top'>
                                    <h2
                                        className={`lh-2-3 on-hover-red pb-1 ${styles.featured_title}`}>
                                        {blogpost.title}
                                    </h2>
                                </div>
                                <p className={`paragraph-text ${styles.post_text}`}>
                                    {' '}
                                    {blogpost.subtitle.substring(0, 130)}
                                </p>
                            </a>
                            <div
                                className='mt-5 d-flex pt-5 lg-reset-margin-top lg-reset-padding-top mobile-margin-reset'
                                style={{ bottom: '0px' }}>
                                <p className='d-flex'>
                                    <GatsbyImage
                                        className={`author-photo-blog mr-3 ${styles.author_image}`}
                                        image={blogpost.author.image.gatsbyImageData}
                                        alt={blogpost.title}
                                    />
                                </p>
                                <p className={`pl-2 f-14 mr-3 fw-600 ${styles.post_text}`}>
                                    <p className={`d-flex ${styles.post_link}`}>
                                        <span className='primary-text'>{blogpost.author.name}</span>
                                    </p>
                                    {new Date(blogpost.date).toLocaleDateString(
                                        'en-US',
                                        dateOptions,
                                    )}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

class BlogListTemplate extends React.Component<StateProps> {
    render() {
        const pageSEO = get(this, 'props.data.allContentfulPages.edges[0].node') as BlogPage

        // PAGINATION
        const { currentPage, numPages } = this.props.pageContext

        const blogPosts: BlogPostList = get(this, 'props.data.allContentfulLensesBlog.edges', [])
        const blogPostsPaginationMeta: PageInfo = get(
            this,
            'props.data.allContentfulLensesBlog.pageInfo',
            {
                currentPage: currentPage,
                hasNextPage: false,
                hasPreviousPage: false,
                itemCount: 0,
                pageCount: 1,
                perPage: numPages,
                totalCount: 0,
            },
        )

        const latestBlogPost: BlogPost | undefined = blogPosts[0]?.node
        const restOfBlogPosts: BlogPost[] = blogPosts.slice(1).map(({ node }) => node)

        return (
            <Layout>
                <Helmet>
                    <title>{`${pageSEO.title.title} ${currentPage}`}</title>
                    <meta name='twitter:text:title' content={pageSEO.title.title} />
                    <meta
                        name='description'
                        content={`${pageSEO.description.description} ${currentPage}`}
                    />
                    <meta property='og:description' content={pageSEO.description.description} />
                    <meta
                        property='twitter:description'
                        content={pageSEO.description.description}
                    />
                    <meta name='keywords' content={`${pageSEO.keywords.keywords} ${currentPage}`} />
                    <meta name='twitter:card' content='summary_large_image' />
                    <meta name='twitter:site' content='@lensesio' />
                    <meta property='og:title' content={pageSEO.title.title} />
                    <meta property='twitter:title' content={pageSEO.title.title} />
                    <meta property='og:image' content={'https:' + pageSEO.oGimage.fluid.srcWebp} />
                    <meta
                        property='twitter:image'
                        content={'https:' + pageSEO.oGimage.fluid.srcWebp}
                    />
                    <link
                        rel='stylesheet'
                        href='https://cdn.jsdelivr.net/npm/docsearch.js@2/dist/cdn/docsearch.min.css'
                    />
                    <script
                        type='text/javascript'
                        src='https://cdn.jsdelivr.net/npm/docsearch.js@2/dist/cdn/docsearch.min.js'
                        // onLoad does not expect strings,
                        // but string is required for docsearch to be functional
                        // @ts-expect-error
                        onLoad="docsearch({apiKey: '32e017aaf6842be76ecbac45550b35fa',
                                     appId: '84ASK1T3ST',
                                     indexName: 'dev_BLOG',
                                     inputSelector: '#searchbox input',
                                     debug: true})"
                        async></script>
                </Helmet>

                <div className={styles.m_blog}>
                    <section className={`pb-5 pt-5 ${styles.featured_background}`}>
                        <div className='container pt-5'>
                            <div className='row mt-5 mb-3'>
                                <div className='col-md-12 col-md-12 text-center pt-0'>
                                    <h1
                                        className={`exclude-algolia-title h2-like ${styles.page_title}`}>
                                        Lenses team is typing
                                    </h1>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className='algolia-container'>
                        <div className='container'>
                            <div className='mt-3 pt-3'>
                                <LatestBlogPost blogpost={latestBlogPost} />
                            </div>
                        </div>
                    </section>

                    {/* AFTER SINGLE DISPLAY */}
                    <div className='container'>
                        <div className='p-6'>
                            <div className='gap-6 md:gap-8 lg:gap-16 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 mt-5'>
                                {restOfBlogPosts.map((blogPost) => (
                                    <BlogPostCard key={blogPost.id} blogpost={blogPost} />
                                ))}
                            </div>
                        </div>
                    </div>

                    <div className='container my-5 flex items-center justify-center'>
                        <Pagination pageInfo={blogPostsPaginationMeta} path={'/blog'} />
                    </div>
                </div>
            </Layout>
        )
    }
}

export default BlogListTemplate

export const pageQuery = graphql`
    query LensesBlogListTemplateIndexQuery($skip: Int!, $limit: Int!) {
        site {
            siteMetadata {
                title
                description
            }
        }
        allContentfulLensesBlog(limit: $limit, skip: $skip, sort: { fields: date, order: DESC }) {
            edges {
                node {
                    id
                    title
                    subtitle
                    slug
                    date
                    description
                    heroimage {
                        gatsbyImageData(formats: WEBP, placeholder: BLURRED, layout: FULL_WIDTH)
                    }
                    updatedAt
                    categories {
                        categoryTitle
                    }
                    tags {
                        tagTitle
                    }
                    author {
                        display
                        name
                        image {
                            gatsbyImageData(formats: WEBP, layout: FULL_WIDTH, placeholder: BLURRED)
                            title
                        }
                    }
                }
            }
            pageInfo {
                currentPage
                hasNextPage
                hasPreviousPage
                itemCount
                pageCount
                perPage
                totalCount
            }
            totalCount
        }
        allContentfulPages(filter: { pageReference: { pageIdentifier: { eq: "Blog" } } }) {
            edges {
                node {
                    id
                    title {
                        title
                    }
                    keywords {
                        keywords
                    }
                    description {
                        description
                    }
                    oGimage {
                        title
                        fluid(quality: 100) {
                            srcWebp
                            srcSetWebp
                        }
                        description
                        updatedAt
                    }
                }
            }
        }
    }
`
